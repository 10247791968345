import * as Constants from "./constants";

const CryptoJS = require('crypto-js')

export function extractPayload (payload) {
    let decrypted = '--error--'
    try {
        const strPwd = Constants.HASH_KEY
        const strIv = Constants.HASH_IV

        const iv = CryptoJS.SHA256(strIv).toString().substring(0, 16)
        const key = CryptoJS.SHA256(strPwd).toString().substring(0, 32)

        const decodedPayload = CryptoJS.enc.Base64.parse(payload).toString(CryptoJS.enc.Utf8)

        const keyObj = CryptoJS.enc.Utf8.parse(key)
        const ivObj = CryptoJS.enc.Utf8.parse(iv)

        decrypted = CryptoJS.AES.decrypt(decodedPayload, keyObj, {
            iv: ivObj,
            mode: CryptoJS.mode.CBC
        }).toString(CryptoJS.enc.Utf8)

        if (decrypted.trim().length === 0) {
            decrypted = '--error--'
        }

        return decrypted
    } catch (e) {
        console.error(e)
    }
    return decrypted
}
