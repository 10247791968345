import i18next from "i18next";
import { initReactI18next } from "react-i18next";
import bg from 'assets/i18n/bg.json';
import bs from '../assets/i18n/bs.json';
import ca from '../assets/i18n/ca.json';
import cs from '../assets/i18n/cs.json';
import de from '../assets/i18n/de.json';
import el from '../assets/i18n/el.json';
import en from '../assets/i18n/en.json';
import es from '../assets/i18n/es.json';
import eu from '../assets/i18n/eu.json';
import fr from '../assets/i18n/fr.json';
import gl from '../assets/i18n/gl.json';
import hr from '../assets/i18n/hr.json';
import hu from '../assets/i18n/hu.json';
import it from '../assets/i18n/it.json';
import ja from '../assets/i18n/ja.json';
import kk from '../assets/i18n/kk.json';
import ko from '../assets/i18n/ko.json';
import mk from '../assets/i18n/mk.json';
import nl from '../assets/i18n/nl.json';
import no from '../assets/i18n/no.json';
import pl from '../assets/i18n/pl.json';
import pt from '../assets/i18n/pt.json';
import ro from '../assets/i18n/ro.json';
import ru from '../assets/i18n/ru.json';
import sk from '../assets/i18n/sk.json';
import sl from '../assets/i18n/sl.json';
import sq from '../assets/i18n/sq.json';
import sr from '../assets/i18n/sr.json';
import sv from '../assets/i18n/sv.json';
import th from '../assets/i18n/th.json';
import uk from '../assets/i18n/uk.json';
import zh from '../assets/i18n/zh.json';
import zhHK from '../assets/i18n/zh-HK.json';
import zhTW from '../assets/i18n/zh-TW.json';

export async function i18next_init(defaultLang) {
    await i18next
        .use(initReactI18next)
        .init({
        interpolation: {escapeValue: false},
        lng: defaultLang,
        supportedLngs: ['bg', 'bs', 'ca', 'cs', 'de', 'el', 'en', 'es', 'eu', 'fr', 'gl', 'hr', 'hu', 'it', 'ja', 'kk', 'ko', 'mk', 'nl', 'no', 'pl', 'pt', 'ro', 'ru', 'sk', 'sl', 'sq', 'sr', 'sv', 'th', 'uk', 'zh', 'zh-CN', 'zh-HK', 'zh-TW'],
        fallbackLng: 'en',
        resources: {
            bg: {
                global: bg, // Bulgarian
            },
            bs: {
                global: bs, // Bosnian
            },
            ca: {
                global: ca, // Catalan
            },
            cs: {
                global: cs, // Czech
            },
            de: {
                global: de, // German
            },
            el: {
                global: el, // Greek
            },
            en: {
                global: en, // English
            },
            es: {
                global: es, // Spanish
            },
            eu: {
                global: eu, // Basque
            },
            fr: {
                global: fr, // French
            },
            gl: {
                global: gl, // Galician
            },
            hr: {
                global: hr, // Croatian
            },
            hu: {
                global: hu, // Hungarian
            },
            it: {
                global: it, // Italian
            },
            ja: {
                global: ja, // Japan
            },
            kk: {
                global: kk, // Kazakh
            },
            ko: {
                global: ko, // Korean
            },
            mk: {
                global: mk, // Macedonian
            },
            nl: {
                global: nl, // Dutch
            },
            no: {
                global: no, // Norwegian
            },
            pl: {
                global: pl, // Polish
            },
            pt: {
                global: pt, // Portuguese
            },
            ro: {
                global: ro, // Romanian
            },
            ru: {
                global: ru, // Russian
            },
            sl: {
                global: sl, // Slovenian
            },
            sk: {
                global: sk, // Slovak
            },
            sq: {
                global: sq, // Albanian
            },
            sr: {
                global: sr, // Serbian
            },
            sv: {
                global: sv, // Swedish
            },
            th: {
                global: th, // Thai
            },
            uk: {
                global: uk, // Ukranian
            },
            zh: {
                global: zh,
            },
            'zh-CN': {
                global: zh, // Chinese
            },
            'zh-HK': {
                global: zhHK,
            },
            'zh-TW': {
                global: zhTW,
            }
        },
    })

    const supportedLanguages = {}
    for (let lang of Object.keys(i18next.services.resourceStore.data)) {
        const nameGenerator = new Intl.DisplayNames([lang], {type: 'language'})
        supportedLanguages[lang] = (nameGenerator.of(lang))
    }

    return [i18next, supportedLanguages]
}

export function getFontFamily(locale) {
    switch (locale) {
        case 'ja':
        case 'jp':
            return 'mplus1p'
        case 'cn':
            return 'notoSansSC'
        case 'zh':
        case 'zh-CN':
        case 'zh-HK':
        case 'zh-TW':
            return 'notoSansTC'
        case 'ko':
        case 'kr':
            return 'ibmPlex'
        default:
            return 'abcMonumentGrotesk'
    }
}

export function getLeapLocale(locale) {
    let l
    if (locale.includes('zh')) {
        l = locale
    } else {
        l = locale.substring(0, 2)
    }
    if (l.localeCompare('bs') === 0) {
        return 'ba'
    } else if (l.localeCompare('cs') === 0) {
        return 'cz'
    } else if (l.localeCompare('ja') === 0) {
        return 'jp'
    } else if (l.localeCompare('ko') === 0) {
        return 'kr'
    } else if (l.localeCompare('sl') === 0) {
        return 'si'
    } else if (l.localeCompare('sq') === 0) {
        return 'al'
    } else if (l.localeCompare('sr') === 0) {
        return 'rs'
    } else if (l.localeCompare('zh-TW') === 0 || l.localeCompare('zh-HK') === 0) {
        return 'zh'
    } else if (l.localeCompare('zh') === 0 || l.localeCompare('zh-CN') === 0) {
        return 'cn'
    } else if (l.localeCompare('el') === 0) {
        return 'gr'
    } else if (l.localeCompare('kk') === 0) {
        return 'kz'
    } else if (l.localeCompare('uk') === 0) {
        return 'ua'
    }

    return l
}
