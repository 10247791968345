import {useEffect, useState} from "react";
import * as Constants from "util/constants";
import {getLeapLocale} from "util/i18_utils";
import {FormData} from "ui/interfaces/form";

interface PageProps {
    locale: string,
    domainUrl: string,
    store: string,
}

export function useFormFetch({locale, domainUrl, store}: PageProps) {
    const [data, setData] = useState<FormData | undefined>()
    const [loading, setLoading] = useState(true);

    const fetchData = ({locale, domainUrl}: { locale: string, domainUrl: string }) => {
        setLoading(true);
        const url = `${Constants.DEBUG ? Constants.DOMAIN_URL_DEV : domainUrl}${Constants.CX_FORM_API_URI}/get_form`
        const leapLocale = getLeapLocale(locale)
        const paramLocale = `locale=${leapLocale}`
        const paramStore = store ? `&store=${store}` : ''
        fetch(`${url}?${paramLocale}${paramStore}`)
            .then((res) => {
                if (res.ok) {
                    return res.json()
                }
                throw new Error('Failed to load questions')
            })
            .then((data) => {
                const formData = data as FormData
                setData(formData)
            }).catch((error) => {
            console.error('Error fetching form data:', error);

        }).finally(() => {
            setLoading(false)
        })
    };

    useEffect(() => {
        fetchData({locale, domainUrl})
    }, [locale]);

    return {data, loading, refetch: fetchData}
}
