import {welcomeVideo} from "../../util/assets_paths";
import {useTranslation} from "react-i18next";
import {useSwipeable} from "react-swipeable";
import 'ui/styles/Welcome.css'

export default function WelcomeVideo({onStart}: { onStart: () => void }) {
    const {t} = useTranslation('global')

    const handlers = useSwipeable({
        onSwipedUp: (event) => onStart(),
    })

    return (
        <div className='h-dvh w-dvw relative overflow-hidden'>
            {/* Background video */}
            <video src={welcomeVideo} muted={true} loop={true} autoPlay={true} className='h-full w-full object-cover absolute inset-0 overflow-hidden'/>
            {/*<img alt='background video' src={welcomeVideo} className='h-full w-full object-cover absolute inset-0 overflow-hidden'/>*/}

            {/* Welcome text and arrow with background shadow on top */}
            <div className='flex flex-col justify-end absolute inset-0 overflow-hidden'>
                {/* Animated center image */}
                {/*<div className="absolute inset-0 flex items-start justify-center pt-32">*/}
                {/*    <img*/}
                {/*        src={welcome}*/}
                {/*        alt="center animation"*/}
                {/*        className="center-image-animation"*/}
                {/*    />*/}
                {/*</div>*/}

                {/* Shadow overlay */}
                <div className="w-full h-[60%] bg-gradient-to-t from-black via-black/90 px-8 flex items-center justify-end relative-hidden">
                    <div className='absolute bottom-20 md:left-32 md:right-32 left-5 right-5 flex items-center justify-end gap-[0.5rem] fade-welcome mb-12' {...handlers}>
                        {/* Welcome text */}
                        <h1 className='text-white md:text-8xl text-[3.5rem] font-[500] uppercase -tracking-[0.188rem] leading-none flex-grow'>{t('cx_match')} {t('welcome')}</h1>

                        {/* Arrows */}
                        <button {...handlers} className="uppercase flex flex-col justify-center items-center" onClick={onStart}>
                            <svg className="arrows">
                                <path className="a1" d="M5 15 L15 5 L25 15"/>
                                <path className="a2" d="M5 35 L15 25 L25 35"/>
                                <path className="a3" d="M5 55 L15 45 L25 55"/>
                                <path className="a4" d="M5 75 L15 65 L25 75"/>
                            </svg>
                        </button>
                    </div>
                </div>


            </div>
        </div>

    )
}
