import approach from 'assets/images/match_approach.png'
import mixMatch from 'assets/images/match_mixmatch.png'
import welcome from 'assets/images/match_welcome.png'
import {useTranslation} from "react-i18next";
import cross from "../../assets/images/cross.png";
import check from "../../assets/images/check.png";
import {useNavigate} from 'react-router-dom';
import 'ui/styles/Transitions.css'
import {CxEnergy, useUser} from "../context/UserContext";
import {useEffect, useState} from "react";
import * as Constants from "../../util/constants";
import {Answer} from "../interfaces/form";

interface FinalData {
    type: string;
    id: number;
    store: string;
    answers: Answer[];
    first_name: string;
    last_name: string;
    comment: string;
}

interface JsonData {
    data: FinalData;
}

export default function Finish() {
    const getMaxEnergy = (energyCount: Map<CxEnergy, number>): CxEnergy | null => {
        let maxEnergy: CxEnergy | null = null;
        let maxCount = -Infinity;

        for (const [energy, count] of energyCount.entries()) {
            if (count > maxCount) {
                maxCount = count;
                maxEnergy = energy;
            } else if (count === maxCount) {
                if (energy === CxEnergy.Approach && (maxEnergy === CxEnergy.Welcomer || maxEnergy === null)) {
                    maxEnergy = energy;
                }
            }
        }

        return maxEnergy;
    };

    const navigate = useNavigate()
    const {formData, form: data, store, answers, energyCount} = useUser()
    const [image, setImage] = useState<string | null>(null)
    const [color, setColor] = useState<string | null>(null)
    const [background, setBackground] = useState<string | null>(null)
    const [comment, setComment] = useState<string | null>(null)
    const maxEnergy = getMaxEnergy(energyCount)

    const {t} = useTranslation('global')

    const saveForm = ({withoutComment}: { withoutComment: boolean }) => {
        if (data) {
            let jsonData: JsonData = {data: {} as FinalData}
            let finalData: FinalData = {
                type: '',
                id: data.id,
                store: '',
                answers: [] as Answer[],
                first_name: '',
                last_name: '',
                comment: ''
            }
            finalData["type"] = "cx_mystery_onboarding_form"
            finalData['id'] = data.id
            finalData['store'] = store
            finalData['answers'] = answers
            finalData['first_name'] = formData.first_name
            finalData['last_name'] = formData.last_name
            finalData['comment'] = withoutComment ? "" : comment ?? ""
            jsonData['data'] = finalData

            const url = `${Constants.DEBUG ? Constants.DOMAIN_URL_DEV : Constants.DOMAIN_URL_PRO}${Constants.CX_FORM_API_URI}/save_form`

            ///Post to url with answers
            fetch(url, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify(jsonData),
            })
                .then(response => response.json())
                .then(() => {
                    navigate('/endPoll')
                })
                .catch((error) => {
                    console.error('Error:', error);
                });
        }
    }


    useEffect(() => {
        if (maxEnergy) {
            /// max energy will be the user context's energyCounts key where the value is the highest; in case of a tie, the first one will be chosen; in case of a tie between 2 and 3, 2 will be chosen

            /// get energy image here key is maxEnergy
            /// Iterate keys of energyImage object and check if key is equal to maxEnergy
            /// If it is equal, set the profile image to the value of that key

            if (maxEnergy == CxEnergy.Welcomer) {
                setImage(welcome)
                setColor("text-[#a91d33]")
                setBackground('bg-[#5BB4B1]')
            } else if (maxEnergy == CxEnergy.Approach) {
                setImage(approach)
                setColor("text-[#a91d33]")
                setBackground('bg-[#F6B2AD]')
            } else if (maxEnergy == CxEnergy.MixMatch) {
                setImage(mixMatch)
                setColor("text-[#ff8737]")
                setBackground('bg-[#FCC360]')
            }
        }
    }, []);


    return (
        image && <div className={`h-dvh w-full flex flex-col items-center justify-between pb-24 ${background} fade-in-page`}>
            <img src={image} className='h-[15rem] object-contain pt-12 mb-32'/>

            <div className='w-full px-4 flex flex-col items-center gap-4'>
                <p className=''>{t('say_something')}</p>
                <input type='text'
                       placeholder={t('write_comment')}
                       className='border-1 border-white rounded-sm p-2 w-full z-50'
                       onChange={(e) => setComment(e.target.value)}
                />

                <div className='flex justify-center gap-12 mt-8'>
                    <button onTouchStart={() => saveForm({withoutComment: true})}>
                        <img src={cross} alt='dislike' className='w-16 h-16'/>
                    </button>
                    <button onTouchStart={() => saveForm({withoutComment: false})}>
                        <img src={check} alt='like' className='w-16 h-16'/>
                    </button>
                </div>
            </div>
        </div>
    )
}
