import logo from 'assets/images/blackLogo.png'
import 'ui/styles/Transitions.css'
import {useTranslation} from "react-i18next";
import parse from "html-react-parser";

export default function End() {
    const { t } = useTranslation('global')

    return (
        <div className='h-dvh w-full flex flex-col items-center justify-between fade-in-page bg-white'>
            <img src={logo} className='max-h-[3rem] object-contain mt-12'/>
            <h1 className='font-extralight text-2xl px-4 text-center pb-12'>
                {parse(t('thanks_answer'))}
            </h1>
            <div></div>
        </div>
    )
}
