import cxMatch from 'assets/images/cx-match.png'
import {useTranslation} from "react-i18next";
import {useEffect, useState} from "react";
import 'ui/styles/Transitions.css'
import {useLocation, useNavigate} from "react-router-dom";
import {useUser} from "../context/UserContext";
import {usePreviousLocation} from "../context/LocationContext";

export default function Start() {
    const [sectionTitles, setSectionTitles] = useState<string[]>([])
    const {t} = useTranslation('global')
    const navigate = useNavigate();
    const {form: data} = useUser()
    const previousLocation = usePreviousLocation()

    useEffect(() => {
        if (previousLocation !== '/register') {
            navigate('/');
        }
    }, [navigate]);

    window.onbeforeunload = function () {
        window.location.replace('/');
        return false;
    }

    useEffect(() => {
        if (data) {
            // Get the section texts names in an Array that we'll use to show in te text
            setSectionTitles(data.form_sections.map((section) => section.text))
        }
    }, [data]);

    return (
        <div className='flex flex-col items-center h-full px-8 py-10 opacity-transition relative'>
            <div className='h-[12rem] w-full bg-white py-4'>
                <img src={cxMatch} alt='cx match logo' className='h-full w-full object-contain'/>
            </div>

            <div className='flex-grow'>
                <h1 className='font-black text-3xl pt-8'>{t('welcome_cx_match')}</h1>
                <p className='text-[#7d7d7d] py-2'>{t('welcome_content')}</p>
                <ul className='py-2 overflow-auto'>
                    {sectionTitles.map((title, index) => (<li
                        className='text-[#7d7d7d] text-lg py-4 border-b-[1px] border-black/10 w-full'
                        key={title}
                    >
                        {index + 1} {title}
                    </li>))}
                </ul>
                <div className='h-[5rem]'></div>
            </div>

            <div className='w-full bg-white p-4 fixed bottom-0 right-0'>
                <button className='generic-black-btn' onClick={() => navigate('/poll')}>{t('start')}</button>
            </div>

        </div>
    )
}
