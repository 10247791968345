import cross from 'assets/images/cross.png';
import check from 'assets/images/check.png';

interface PageProps {
    onClick: (direction: string, value: string | null, answer_id: number | null, comment: string | null) => void,
}

export default function YesNo({ onClick }: PageProps) {
    return (
        <div className='flex gap-8 items-center justify-center pb-12 pt-8 z-50'>
            <button
                style={{ pointerEvents: 'auto' }}
                onTouchStart={() => onClick('right', '0', null, null)}
            >
                <img src={cross} alt='dislike' className='w-16 h-16'/>
            </button>
            <button
                style={{ pointerEvents: 'auto' }}
                onTouchStart={() => onClick('right', '1', null, null)}
            >
                <img src={check} alt='like' className='w-16 h-16'/>
            </button>
        </div>
    )
}
