import React from "react";
import useWindowSize from "util/useWindowSize";
import {animated, useSpring} from "@react-spring/web";

const settings = {
    maxTilt: 25, // in deg
    rotationPower: 50,
    swipeThreshold: 150 // need to update this threshold for RN (1.5 seems reasonable...?)
}

// physical properties of the spring
const physics = {
    touchResponsive: {
        friction: 70,
        tension: 1000
    },
    animateOut: {
        friction: 40,
        tension: 500
    },
    animateBack: {
        friction: 20,
        tension: 300
    }
}

const pythagoras = (x, y) => {
    return Math.sqrt(Math.pow(x, 2) + Math.pow(y, 2))
}

const normalize = (vector) => {
    const length = Math.sqrt(Math.pow(vector.x, 2) + Math.pow(vector.y, 2))
    return {x: vector.x / length, y: vector.y / length}
}

const animateOut = async (gesture, setSpringTarget, windowHeight, windowWidth) => {
    const diagonal = pythagoras(windowHeight, windowWidth)
    const velocity = pythagoras(gesture.x, gesture.y)
    const finalX = diagonal * gesture.x
    const finalY = diagonal * gesture.y
    const finalRotation = gesture.x * 45
    const duration = diagonal / velocity

    setSpringTarget.start({
        xyrot: [finalX, 0, finalRotation],
        config: {duration: duration}
    })

    // for now animate back
    return await new Promise((resolve) =>
        setTimeout(() => {
            resolve()
        }, duration)
    )
}

const animateBack = (setSpringTarget) => {
    // translate back to the initial position
    return new Promise((resolve) => {
        setSpringTarget.start({xyrot: [0, 0, 0], config: physics.animateBack, onRest: resolve})
    })
}

const getSwipeDirection = (property) => {
    if (Math.abs(property.x) > Math.abs(property.y)) {
        if (property.x > settings.swipeThreshold) {
            return 'right'
        } else if (property.x < -settings.swipeThreshold) {
            return 'left'
        }
    }
    return 'none'
}

const AnimatedDiv = animated.div


const TinderCard = React.forwardRef(
    (
        {
            flickOnSwipe = true,
            children,
            onSwipe,
            onCardLeftScreen,
            className,
            preventSwipe = [],
            swipeRequirementType = 'velocity',
            swipeThreshold = settings.swipeThreshold,
            onSwipeRequirementFulfilled,
            onSwipeRequirementUnfulfilled,
            onSwipeYesNo: onSwipeYesNo = () => {}
        },
        ref
    ) => {
        const {width, height} = useWindowSize()
        const [{xyrot}, setSpringTarget] = useSpring(() => ({
            xyrot: [0, 0, 0],
            config: physics.touchResponsive
        }))

        const swipeDistanceThreshold = swipeThreshold;

        const element = React.useRef();

        // Internal state to track dragging
        const [isDragging, setIsDragging] = React.useState(false);
        const [dragStart, setDragStart] = React.useState({x: 0, y: 0})

        const handleSwipe = React.useCallback(
            (dir) => {
                handleDragEnd(dir === 'right' ? width : -width, 0, 0, 0);
                // const offsetX = dir === 'right' ? width : -width;
                // if (onSwipe) onSwipe(dir);
                // animateOut({ x: offsetX, y: 0 }, setSpringTarget, width, height).then(() => {
                //     if (onCardLeftScreen) onCardLeftScreen(dir);
                // });
            },
            [onSwipe, onCardLeftScreen, setSpringTarget, width, height]
        );

        // Expose swipe method via ref
        React.useImperativeHandle(ref, () => ({
            swipe: (dir) => handleSwipe(dir)
        }));

        // Function to handle dragging motion
        const handleDrag = React.useCallback(
            (dx, dy) => {
                // Limit motion to horizontal movement only
                if (Math.abs(dx) > Math.abs(dy)) {
                    const rotation = (dx / width) * settings.maxTilt; // Create rotation effect
                    onSwipeYesNo(dx > 0 ? 'yes' : 'no');
                    setSpringTarget.start({
                        xyrot: [dx, 0, rotation], // Only move horizontally
                        config: physics.touchResponsive
                    });
                }
            },
            [width, setSpringTarget]
        );

        // Handle release of the card after dragging
        const handleDragEnd = React.useCallback(
            (dx, dy, vx, vy) => {
                onSwipeYesNo(null)
                if (Math.abs(dx) > swipeDistanceThreshold) {
                    const direction = getSwipeDirection({x: dx, y: dy});
                    if (flickOnSwipe && !preventSwipe.includes(direction)) {
                        // If it's a valid swipe, trigger the swipe
                        if (onSwipe) onSwipe(direction);
                        animateOut({x: dx, y: dy, vx, vy}, setSpringTarget, width, height).then(() => {
                            if (onCardLeftScreen) onCardLeftScreen(direction);
                        });
                    } else {
                        // If it's not a valid swipe, animate back to original position
                        animateBack(setSpringTarget);
                    }
                } else {
                    // If the swipe distance is below the threshold, animate back to original position
                    animateBack(setSpringTarget);
                }
            },
            [flickOnSwipe, preventSwipe, onSwipe, onCardLeftScreen, setSpringTarget, width, height]
        );


        // Mouse and touch events
        React.useLayoutEffect(() => {
            const handleMouseDown = (ev) => {
                setIsDragging(true);
                setDragStart({x: ev.clientX, y: ev.clientY});
            };

            const handleMouseMove = (ev) => {
                if (!isDragging) return;
                const dx = ev.clientX - dragStart.x;
                const dy = ev.clientY - dragStart.y;
                handleDrag(dx, dy);
            };

            const handleMouseUp = (ev) => {
                if (!isDragging) return;
                setIsDragging(false);
                const dx = ev.clientX - dragStart.x;
                const dy = ev.clientY - dragStart.y;
                handleDragEnd(dx, dy, 0, 0); // No velocity here as it's based on mouse movement
            };

            const handleTouchStart = (ev) => {
                setIsDragging(true);
                setDragStart({x: ev.touches[0].clientX, y: ev.touches[0].clientY});
            };

            const handleTouchMove = (ev) => {
                if (!isDragging) return;
                const dx = ev.touches[0].clientX - dragStart.x;
                const dy = ev.touches[0].clientY - dragStart.y;
                handleDrag(dx, dy);
            };

            const handleTouchEnd = (ev) => {
                if (!isDragging) return;
                setIsDragging(false);
                const dx = ev.changedTouches[0].clientX - dragStart.x;
                const dy = ev.changedTouches[0].clientY - dragStart.y;
                handleDragEnd(dx, dy, 0, 0); // No velocity in touch, based on drag distance
            };

            // Add event listeners for dragging
            element.current.addEventListener('mousedown', handleMouseDown);
            window.addEventListener('mousemove', handleMouseMove);
            window.addEventListener('mouseup', handleMouseUp);

            element.current.addEventListener('touchstart', handleTouchStart);
            element.current.addEventListener('touchmove', handleTouchMove);
            element.current.addEventListener('touchend', handleTouchEnd);

            // Cleanup listeners
            return () => {
                element.current.removeEventListener('mousedown', handleMouseDown);
                window.removeEventListener('mousemove', handleMouseMove);
                window.removeEventListener('mouseup', handleMouseUp);

                element.current.removeEventListener('touchstart', handleTouchStart);
                element.current.removeEventListener('touchmove', handleTouchMove);
                element.current.removeEventListener('touchend', handleTouchEnd);
            };
        }, [isDragging, dragStart, handleDrag, handleDragEnd]);

        return (
            <AnimatedDiv
                ref={element}
                className={className}
                style={{
                    transform: xyrot.to((x, y, rot) => `translate3d(${x}px, ${y}px, 0) rotate(${rot}deg)`),
                }}
            >
                {children}
            </AnimatedDiv>
        );
    }
)

export default TinderCard;
