// src/hooks/PreviousLocationProvider.tsx
import { createContext, useContext, useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';

const PreviousLocationContext = createContext<string | null>(null);

export const PreviousLocationProvider = ({ children }: { children: React.ReactNode }) => {
    const location = useLocation();
    const [previousLocation, setPreviousLocation] = useState<string | null>(null);

    useEffect(() => {
        // Update the previous location state whenever the route changes
        setPreviousLocation(location.pathname);
    }, [location]);

    return (
        <PreviousLocationContext.Provider value={previousLocation}>
            {children}
        </PreviousLocationContext.Provider>
    );
};

export const usePreviousLocation = () => {
    return useContext(PreviousLocationContext);
};
