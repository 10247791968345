import cross from 'assets/images/cross.png';
import check from 'assets/images/check.png';
import { useState } from 'react';
import {useTranslation} from "react-i18next";

interface PageProps {
    onClick: (direction: string, value: string | null, answer_id: number | null, comment: string | null) => void,
}

export default function Text({ onClick }: PageProps) {
    const [comment, setComment] = useState<string | null>(null)
    const { t } = useTranslation('global')

    const handleTextChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        e.preventDefault()
        setComment(e.target.value)
    }

    return (
        <div className="flex flex-col gap-4 z-50 pb-12 pt-8">
            <input type='text' placeholder={t('type_answer')} className='border-2 border-gray-300 rounded-md p-2 w-full z-50' onChange={handleTextChange}/>

            {/* Buttons - NO will erase the text and send null value; YES will send message if there is, null otherwise */}
            <div className='z-50 w-full flex justify-center gap-8'>
                <button onClick={() => onClick('left', null, null, null)} onTouchStart={() => onClick('left', null, null, null)}>
                    <img src={cross} alt='dislike' className='w-16 h-16'/>
                </button>
                <button onClick={() => onClick('right', comment, null, null)} onTouchStart={() => onClick('right', comment, null, null)}>
                    <img src={check} alt='like' className='w-16 h-16'/>
                </button>
            </div>
        </div>
    )
}
