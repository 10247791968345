import {useUser} from "../context/UserContext";
import {ChangeEvent, useEffect, useState} from "react";
import {useLocation, useNavigate} from "react-router-dom";
import {UserFormData} from "../interfaces/user";
import storeCover from 'assets/images/store-cover.jpg';
import {useTranslation} from "react-i18next";
import 'ui/styles/Transitions.css'
import {usePreviousLocation} from "../context/LocationContext";

export default function UserRegister() {
    const {setFirstName, setLastName} = useUser();
    const [localData, setLocalData] = useState<UserFormData>({first_name: '', last_name: '', comment: 'test commet'});
    const navigate = useNavigate();
    const {t} = useTranslation('global');
    const [validData, setValidData] = useState(true);
    const previousLocation = usePreviousLocation();

    useEffect(() => {
        if (previousLocation !== '/') {
            navigate('/');
        }
    }, [navigate]);

    window.onbeforeunload = function () {
        window.location.replace('/');
        return false;
    }

    const handleSubmit = (e: React.FormEvent) => {
        e.preventDefault();
        if (localData.first_name.length <= 0 || localData.last_name.length <= 0) {
            setValidData(false);
            return;
        }
        setFirstName(localData.first_name);
        setLastName(localData.last_name);
        navigate('/start'); // Navigate to the next page
    };

    const handleInputChange = (e: ChangeEvent<HTMLInputElement>, isFirstName: boolean) => {
        setValidData(true)
        if (isFirstName) {
            setLocalData(prev => ({...prev, first_name: e.target.value}))
        } else {
            setLocalData(prev => ({...prev, last_name: e.target.value}))

        }
    }

    return (
        <div className='h-dvh w-full flex flex-col items-center opacity-transition'>
            {/*Image cover*/}
            <div className='h-[40vh] sm-h-[15%]'>
                <img src={storeCover} alt='store cover' className='object-cover min-w-full h-full'/>
            </div>

            {/*Welcome*/}
            <div className='text-center py-8'>
                <h1 className='font-bold text-4xl'>{t("hello")}</h1>
                <p className='text-black/60 text-lg pt-2'>{t("enter_data")}</p>
            </div>

            {/*Enter user data*/}
            <form onSubmit={handleSubmit} className='flex-grow flex flex-col items-center justify-between w-full px-10 pb-12'>
                <div className='w-full'>
                    <label>
                        <input type='text'
                               placeholder=" "
                               value={localData.first_name}
                               onChange={e => handleInputChange(e, true)}/>
                        <span>{t('name')}</span>
                    </label>

                    <label>
                        <input type='text'
                               placeholder=" "
                               value={localData.last_name}
                               onChange={e => handleInputChange(e, false)}/>
                        <span>{t('last_name')}</span>
                    </label>
                </div>

                <div className='w-full mb-12'>
                    <p className={`text-center italic text-sm ${validData ? 'text-transparent' : 'text-red-500'}`}>{t('error_enter_data')}</p>
                    <button type='submit' className='generic-black-btn'>{t('next')}</button>
                </div>
            </form>
        </div>
    )
}
