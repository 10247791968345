export const DEBUG = false;
export const DEBUG_STORE = 'UmJSWGZQcDR6Vk1wWnladWxjcFlrZz09';

export const DOMAIN_URL_DEV = 'https://dev.leapco.pro/';
export const DOMAIN_URL_PRO = 'https://leapco.pro/'
export const DOMAIN_URL_PRO_CN = 'https://leap.inditex.cn/'

export const CX_FORM_API_URI = 'api/v1/resources/cx_mystery_onboarding'

export const HASH_KEY = 'mNPXNjILl9raekq1MRQ0OtF-8EfIwpb-OotrAC3gadryEsQS5Xs_w7giY-hOXoJCfy4jM3t2vv'
export const HASH_IV = 'x880008510993'
