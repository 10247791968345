import {Answer, Question} from "ui/interfaces/form";
import React, {useEffect, useState} from "react";
import 'ui/styles/Poll.css'
import SwipeCard from "../components/SwipeCard";
import * as Constants from "util/constants";
import {PulseLoader} from "react-spinners";
import {useUser} from "../context/UserContext";
import {usePreviousLocation} from "../context/LocationContext";
import {useNavigate} from "react-router-dom";

export default function Poll() {
    const [swipeQuestions, setSwipeQuestions] = useState<Question[]>();
    const { form: data, setAnswers, answers, formData, setProfile, store } = useUser()
    const previousLocation = usePreviousLocation()
    const navigate = useNavigate();

    useEffect(() => {
        // Check if there is no history or if the navigation stack is empty
        if (previousLocation !== '/start') {
            navigate('/');
        }

    }, [navigate]);

    window.onbeforeunload = function () {
        window.location.replace('/');
        return false;
    }

    useEffect(() => {
        // set body to overflow hidden to avoid showing swiped questions
        //document.body.style.overflow = 'hidden';

        if (data) {
            //get all questions from all sections and reverse them
            const questions = data.form_sections.flatMap(section => section.questions);
            questions.reverse()
            setSwipeQuestions(questions);

            //get answers set to null and will be updated on each question
            const auxAnswers = [] as Answer[];
            for (const question of questions) {
                auxAnswers.push({question_id: question.id, answer_id: null, comment: null, value: null, valid: true});
            }
            //order by question id
            auxAnswers.sort((a, b) => a.question_id - b.question_id);
            setAnswers(auxAnswers);
        }
    }, [data]);


    if (!data) {
        return <div>
            <PulseLoader
                color='#000'
                cssOverride={{ display: 'flex', margin: '0 auto', padding: '5px' }}
                size={10}
                aria-label="Loading Spinner"
                data-testid="loader"
            />
        </div>
    }

    return (
        <div className='cardContainer'>
            <SwipeCard swipeQuestions={swipeQuestions}/>
        </div>
    )
}
