import {AnswerOption} from "../interfaces/form";
import radioEmpty from '../../assets/images/radio-empty.png';
import check from '../../assets/images/check.png';
import {useState} from "react";
import 'ui/styles/Common.css'

interface PageProps {
    answerOptions: AnswerOption[]
    onClick: (direction: string, value: string | null, answer_id: number | null, comment: string | null) => void,
}

export default function Radio({answerOptions, onClick}: PageProps) {
    const [selected, setSelected] = useState<number | null>(null);

    const handleSelected = (optionId: number) => {
        setSelected(optionId);

        //Wait 500ms to show the selected image and then execute swipe and save the answer
        setTimeout(() => {
            onClick('right', null, optionId, null)
        }, 500)
    }

    return (
        <div className='w-full flex flex-col items-center pb-12 pt-8'>
            {answerOptions.map((option: AnswerOption) => (
                <div key={option.id} className='w-full flex gap-4 items-center justify-between py-4 border-b-gray-200/70 border-b-[1px]'>
                    <p className='flex-grow text-sm'>{option.text}</p>
                    <button key={option.id} className='w-24 max-w-[30px] z-50' onTouchStart={() => handleSelected(option.id)}>
                        <img src={selected === option.id ? check : radioEmpty} alt='empty radio button icon' className='w-full object-cover'/>
                    </button>
                </div>

            ))}
        </div>
    )
}
