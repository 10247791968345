import './App.css';
import 'ui/styles/Fonts.css';
import 'ui/styles/Accessibility.css'
import {useEffect, useState} from "react";
import {getFontFamily, i18next_init} from "./util/i18_utils";
import {I18nextProvider} from 'react-i18next'
import Splash from 'ui/pages/Splash'
import {BrowserRouter, Route, Routes} from "react-router-dom";
import Poll from "./ui/pages/Poll";
import UserRegister from "./ui/pages/UserRegister";
import {useUser} from "./ui/context/UserContext";
import Start from "./ui/pages/Start";
import LanguageSelector from "./ui/pages/LanguageSelector";
import Finish from "./ui/pages/Finish";
import End from "./ui/pages/End";
import {useFormFetch} from "./ui/hooks/useFormFetch";
import * as Constants from "./util/constants";
import {PreviousLocationProvider} from "./ui/context/LocationContext";
import {extractPayload} from "./util/app_utils";
import AccessibilityMenu from "./ui/components/AccessibilityMenu";

export default function App() {
    // get store parameter
    const queryParams = new URLSearchParams(window.location.search)
    const hashStore = queryParams.get('store')

    // get languages and other derived data
    const lang = navigator.language
    const [i18n, setI18n] = useState(null)
    const [supportedLanguages, setSupportedLanguages] = useState({})
    const [fontFamily, setFontFamily] = useState(getFontFamily(lang))

    // get data and set it in user context
    const {data} = useFormFetch({locale: lang, domainUrl: Constants.DOMAIN_URL_PRO})
    const {setForm, setStore} = useUser()

    useEffect(() => {
        if (!hashStore && Constants.DEBUG) {
            setStore(extractPayload(Constants.DEBUG_STORE))
        } else if (hashStore != null) {
            setStore(extractPayload(hashStore))
        }
    }, [hashStore])

    useEffect(() => {
        if (data) setForm(data)
    }, [data]);


    /// Config first time on start -> init lang
    useEffect(() => {

        /// init lang and set supported languages available in languages wheel
        i18next_init().then(([i18, langValues]) => {
            setSupportedLanguages(langValues)
            setI18n(i18)
        })

    }, []);

    if (!i18n) {
        return <div>Loading...</div>; // or any loading indicator
    }


// First we get the viewport height and we multiply it by 1% to get a value for a vh unit
    let vh = window.innerHeight * 0.01;
// Then we set the value in the --vh custom property to the root of the document
    document.documentElement.style.setProperty('--vh', `${vh}px`);

    return (
        <BrowserRouter>
            <PreviousLocationProvider>
                <I18nextProvider i18n={i18n}>
                    <main className={fontFamily}>
                        <AccessibilityMenu locale="es-ES" />
                        <Routes>
                            <Route path="/" element={<Splash font={fontFamily} initLang={lang}/>}/>
                            <Route path="/language" element={<LanguageSelector supportedLanguages={supportedLanguages}/>}/>
                            <Route path="/register" element={<UserRegister/>}/>
                            <Route path="/start" element={<Start/>}/>
                            <Route path="/poll" element={<Poll/>}/>
                            <Route path="/finish" element={<Finish/>}/>
                            <Route path="/endPoll" element={<End/>}/>
                        </Routes>
                    </main>
                </I18nextProvider>
            </PreviousLocationProvider>
        </BrowserRouter>

    );
}
