import {useTranslation} from "react-i18next";
import {useNavigate} from 'react-router-dom';
import 'ui/styles/Common.css'
import parse from 'html-react-parser'
import ReactModal from "react-modal";

export default function LegalModal({font, isOpen, onClose, legalContent}) {
    const {t, i18n} = useTranslation('global')
    const navigate = useNavigate();

    const handleAccept = () => {
        onClose()
        navigate('/register');
    };

    const handleClose = () => {
        onClose()
        navigate('/endPoll');
    };

    return (
        <ReactModal
            isOpen={isOpen}
            ariaHideApp={false}
            className={`w-full md:w-[500px] h-[80%] bg-white text-black fixed bottom-0 md:left-[calc(50%-250px)] rounded-t-[20px] px-6 md:px-12 py-10 flex flex-col justify-between modal modal-transition ${font} overflow-scroll`}
        >
            <div className="flex-grow">
                <h1>{t('legal_text')}</h1>
                <div className="max-h-[20rem] small:max-h-[8rem] overflow-auto ">
                    {parse(legalContent)}
                </div>
            </div>
            <div className="">
                <button className="block generic-black-btn mb-5" onClick={handleAccept}>
                    {t('accept_close')}
                </button>
                <button className="block generic-black-btn" onClick={handleClose}>
                    {t('close')}
                </button>
            </div>
        </ReactModal>
    )
}
