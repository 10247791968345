import {FormSection, Question} from "../interfaces/form";
import parse from "html-react-parser";
import 'ui/styles/Poll.css'
import YesNo from "./YesNo";
import Radio from "./Radio";
import Text from "./Text";
import {useEffect} from "react";
import Rating from "./Rating";
import {onboardingSectionGifs} from "../../util/assets_paths";
import {FaArrowLeft} from "react-icons/fa";

interface PageProps {
    question: Question,
    section: FormSection,
    onClick: (direction: string, value: string | null, answer_id: number | null, comment: string | null) => void,
    totalQuestions: number,
    currentQuestionIndex: number
    currentSectionIndex: number
    back: (() => Promise<void>) | null
    dir: string | null
    canGoBack: boolean
}

export default function PollSwipeCard({
                                          question,
                                          currentQuestionIndex,
                                          onClick,
                                          totalQuestions,
                                          section,
                                          currentSectionIndex,
                                          back,
                                          dir,
                                          canGoBack
                                      }: PageProps) {

    const formField = (question: Question) => {
        switch (question.question_type) {
            case 4:
                return <YesNo onClick={onClick}/>
            case 1:
                return <Radio onClick={onClick} answerOptions={question.answers}/>
            case 2:
                return <Rating onClick={onClick}/>
            case 0:
                return <Text onClick={onClick}/>
        }
    }

    useEffect(() => {
        // change root variable --progressWidth the actual width according to the current question
        const progressWidth = ((totalQuestions - currentQuestionIndex) / totalQuestions) * 100;
        document.documentElement.style.setProperty('--progressWidth', `${progressWidth.toString()}%`)
    }, [currentQuestionIndex]);

    return (
        <div className='h-full w-full flex flex-col items-center justify-start relative pb-12 overflow-scroll scrollbar-hidden'>
            {/* Absolute container with image and section name with progress */}
            {section && <div className='w-full h-[60%] inset-0 section-image relative'>
                <div id='imageLike' className={`section-image__like`}></div>
                <div id='imageDislike' className={`section-image__dislike`}></div>
                <img src={onboardingSectionGifs[currentSectionIndex]} alt='section' className='h-full w-full object-cover relative'/>
                <div className='flex flex-col items-center absolute inset-4'>
                    <div className={`flex items-center w-full ${(canGoBack && back) ? 'justify-between' : 'justify-center'} pb-3`}>
                        {(canGoBack && back) && <button onClick={() => back()} className='z-50'>
                            <FaArrowLeft color='#fff'/>
                        </button>}
                        <h1 className='text-white text-[1.25rem]'>{section.text}</h1>
                        {(canGoBack && back) && <FaArrowLeft color='rgba(0,0,0,0)'/>}
                    </div>
                    <div className='progress-bar'/>
                </div>
            </div>}

            <div className='max-h-[50%] z-[999] flex flex-col justify-end  px-4'>
                <div className='h-full'>
                    <p className='text-center text-xl font-[600]'>{parse(question.text)}</p>

                    {/* Form field according to question that will trigger the swipe effect */}
                    {formField(question)}
                </div>
            </div>

        </div>
    )
}
