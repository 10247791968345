import React, {createContext, ReactNode, useContext, useState} from 'react';
import {UserFormData} from 'ui/interfaces/user';
import {Answer, FormData} from "../interfaces/form";
import {Profile} from "../interfaces/profile";

export enum CxEnergy {
    MixMatch = 5,
    Approach = 3,
    Welcomer = 1
}

export const findCxEnergyById = (id: number): CxEnergy | undefined => {
    const energyArray = Object.values(CxEnergy)
        .filter(value => typeof value === "number")
        .map(value => ({
            id: value as number,
            name: CxEnergy[value as number]
        }));

    const foundEnergy = energyArray.find(energy => energy.id === id);

    return foundEnergy ? (foundEnergy.id as CxEnergy) : undefined;
};

const initialFormData: UserFormData = {
    first_name: '',
    last_name: '',
    comment: 'test comment',
};

const initialLocale = navigator.language;

const initialForm: FormData = {
    id: 0,
    created_at: '',
    updated_at: '',
    deleted_at: '',
    text: '',
    form_type: 0,
    version: 0,
    description: '',
    welcome_text: '',
    goodbye_text: '',
    legal_text: '',
    form_sections: []
};

interface UserContextType {
    store: string;
    form: FormData;
    formData: UserFormData;
    locale: string;
    answers: Answer[];
    setFirstName: (first_name: string) => void;
    setLastName: (last_name: string) => void;
    setComment: (comment: string) => void;
    setAnswers: (answers: Answer[]) => void;
    setLocale: (locale: string) => void;
    setForm: (form: FormData) => void;
    profile: Profile;
    setProfile: (profile: Profile) => void;
    setStore: (store: string) => void;
    energyCount: Map<CxEnergy, number>;
    updateEnergyCount: (type: CxEnergy) => void;
}

// Create the Context with an undefined default value
const UserContext = createContext<UserContextType | undefined>(undefined);

// Create a custom hook for consuming the context
export const useUser = (): UserContextType => {
    const context = useContext(UserContext);
    if (!context) {
        throw new Error('useUser must be used within a UserProvider');
    }
    return context;
};

// Create a provider component
interface UserProviderProps {
    children: ReactNode;
}

export const UserProvider: React.FC<UserProviderProps> = ({children}) => {
    const [formData, setFormData] = useState<UserFormData>(initialFormData);
    const [locale, setLocale] = useState<string>(initialLocale);
    const [form, setForm] = useState<FormData>(initialForm);
    const [answers, setAnswers] = useState<Answer[]>([]);
    const [profile, setProfile] = useState<Profile>({type: '', id: '', attributes: {comment: '', max_energy_id: 0}});
    const [store, setStore] = useState<string>('');
    const [energyCount, setEnergyCount] = useState<Map<CxEnergy, number>>(new Map([
        [CxEnergy.MixMatch, 0],
        [CxEnergy.Approach, 0],
        [CxEnergy.Welcomer, 0]
    ]));


    // Methods to update specific parts of the form data
    const setFirstName = (first_name: string) => setFormData(prev => ({...prev, first_name}));
    const setLastName = (last_name: string) => setFormData(prev => ({...prev, last_name}));
    const setComment = (comment: string) => setFormData(prev => ({...prev, comment}));

    // Method to update energyCounts
    const updateEnergyCount = (type: CxEnergy) => {
        setEnergyCount(prev => {
            const updatedMap = new Map(prev);
            updatedMap.set(type, (updatedMap.get(type) ?? 0) + 1);
            return updatedMap;
        });
    };


    return (
        <UserContext.Provider value={{
            form,
            formData,
            locale,
            answers,
            setFirstName,
            setLastName,
            setComment,
            setAnswers,
            setLocale,
            setForm,
            profile,
            setProfile,
            store,
            setStore,
            energyCount,
            updateEnergyCount,
        }}>
            {children}
        </UserContext.Provider>
    );
};
