import starEmpty from '../../assets/images/star_empty.png';
import fireEmpty from '../../assets/images/fire_outlined.png';
import starFilled from '../../assets/images/star_filled.png';
import fireFilled from '../../assets/images/fire_filled.png';
import {useState} from "react";
import {v4 as uuidV4} from 'uuid';

interface PageProps {
    onClick: (direction: string, value: string | null, answer_id: number | null, comment: string | null) => void,
}

export default function Rating({onClick}: PageProps) {
    const [rate, setRate] = useState<number>(0);
    const maxRating = 6;

    const handleRate = (value: number) => {
        if (rate === value) {
            setRate(0);
        } else {
            setRate(value);
        }

        // Wait 500ms to show effect on images and execute swipe function and save answer
        setTimeout(() => {
            onClick('right', value.toString(), null, null)
        }, 500)
    }

    return (
        <div className='flex gap-4 items-end justify-center mb-12 mt-8 h-16'>
            {(Array.from({length: maxRating}, (_, index) => index)).map((e, index) => (
                <button key={uuidV4()} onTouchStart={() => handleRate(index + 1)}>
                    {
                    index == 5 ? <img src={index <= (rate - 1) ? fireFilled : fireEmpty} alt='star icon' className={`${index <= (rate - 1)? 'h-16' : 'h-10'} w-10`}/>
                        : <img src={index <= (rate - 1) ? starFilled : starEmpty} alt='star icon' className='h-10 w-10'/>

                    }
                </button>
            ))}
        </div>
    )
}
