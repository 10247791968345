import {TbWorld} from "react-icons/tb";

interface PageProps {
    locale: string,
    onClick: () => void
}

export function LanguageSelectorTab({locale, onClick}: PageProps) {
    const localeShortCode = locale.split('-')[0]

    return (
        <a
            className="cursor-pointer absolute bottom-[40%] left-0 px-2 py-4 bg-black flex flex-col items-center gap-3 rounded-r-sm rounded-b-sm language-transition delayed-animation"
            onClick={onClick}
        >
            <p className="locale-text">{localeShortCode}</p>
            <TbWorld color='#fff'/>
        </a>
    )
}
