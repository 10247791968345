
import entrance from '../assets/images/entrance.gif';
import shopFloor from '../assets/images/shopfloor.gif';
import sizes from '../assets/images/sizes.gif';
import onlinePickUp from '../assets/images/online_pick_up.gif';
import fittingRoom from '../assets/images/fitting_room.gif';
import cashDesk from '../assets/images/cash_desk2.gif';
import feedback from '../assets/images/feedback.gif';

import entranceVideo from '../assets/videos/entrance_feedback.mp4';
import shopFloorVideo from '../assets/videos/shopfloor_feedback.mp4';
import sizesVideo from '../assets/videos/sizes.mp4';
import onlinePickUpVideo  from '../assets/videos/online_pick_up_feedback.mp4';
import fittingRoomVideo  from '../assets/videos/fitting_room_feedback.mp4';
import cashDeskVideo  from '../assets/videos/cash_desk_feedback.mp4';
import siloVideo  from '../assets/videos/silo_feedback.mp4';

// import welcome from '../assets/images/welcome_entrance.gif';
import welcome from '../assets/videos/welcome2.mp4';

export const onboardingSectionGifs = [
    entrance,
    shopFloor,
    sizes,
    onlinePickUp,
    fittingRoom,
    cashDesk,
    feedback
]

export const onboardingFeedbackVideos = [
    entranceVideo,
    shopFloorVideo,
    sizesVideo,
    onlinePickUpVideo,
    fittingRoomVideo,
    cashDeskVideo,
    siloVideo
]

export const welcomeVideo = welcome;
